.detail-container {
  width: 100%;
  height: 100%;
}

.content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.content-wrap{
  display: flex;
  flex-direction: column;width: 100%;
  height: 80%;
  overflow-y: scroll;
  margin-bottom: 12px;
}

.content img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}
.content p {
  font-weight: 600;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: -18px;
  font-size: 18px;
}
.status{
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}
.call-icon {
  width: 45px;
  height: 45px;
  background-color: var(--primary);
  border-radius: 50%;
  display: flex;

  align-items: center;
  margin-right: 10px;
  justify-content: center;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.5);
  /* animation: bounce 1s 0.1s forwards infinite; */
}

.bottom-png-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
@media screen and (max-width: 800px) {
  .bottom-png-container{
    display: flex;
    flex-direction: column;
  }
} 
@keyframes bounce {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-10px);
  }
  90% {
    transform: translateY(-10px);
  }
}
