@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,700;1,500&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary: #045DE9;
  --secondary: #09C6F9;
}

html,
body {
  height: 100%;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

#root {
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.paper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.container {
  width: 90%;
  height: 90%;
}

.danger {
  background-color: red !important;
}

.loader {
  width: 25px;
  height: 25px;
  border: 3px solid rgba(255, 255, 255, 0.383);
  border-top: 3px solid #fff;
  animation: circle 1s 0.2s forwards ease-in-out infinite;
  margin: auto;
  border-radius: 50%;
}
.loader2 {
  width: 75px;
  height: 75px;
  border: 3px solid #1828ef;
  border-top: 3px solid #9f9f9f;
  animation: circle 1s 0.2s forwards ease-in-out infinite;
  margin: auto;
  border-radius: 50%;
}

.btnx {
  outline: none;
  border: none;
  cursor: pointer;
  width: 90%;
  padding: 10px;
  color: #fff;
  font-family: inherit;
  font-size: 17px;
  font-weight: 500;
  border-radius: 6px;
  background: linear-gradient(to right, var(--primary) 60%, var(--secondary));
}
.mtm {
  margin-top: 10px;
}
.perm-btn {
  width: 90%;
  padding: 15px 0;
  background-color: var(--primary);
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 100px;
  font-family: inherit;
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 17px;
  text-transform: uppercase;
}
@keyframes circle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
