.call-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 93%;
  justify-content: space-between;
  position: relative;
}

.thank-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1;
  justify-content: space-between;
}

.call {
  box-shadow: none;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
}

.connect::before {
  animation: pulse 1.1s infinite;
}

.call-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.call-detail p {
  text-align: center;
}

.call-detail img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
}

.call-img {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-main {
  /* background-color: red; */
  width: 100%;
  height:calc(100% - 90px);
}

.call-main video{
  height:100%;
  width:100%
}

.call-img p {
  font-weight: 600;
}
.call-img p span {
  font-size: 20px;
  animation: dotfade 0.5s forwards infinite;
}
.call-img p span:nth-child(2) {
  animation-delay: 0.5;
}
.call-img p span:nth-child(3) {
  animation-delay: 1;
}

@keyframes dotfade {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.connect::before {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  background-color: var(--primary);
  width: 100%;
  height: 100%;
  z-index: 1;
}

@keyframes pulse {
  0% {
    transform: scale(0.85);
    opacity: 0.8;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}

.call-detail p {
  font-size: 22px;
  font-weight: 600;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-top: 20px; */
}

.root-p {
  font-size: 22px;
  font-weight: 600;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.call-actions {
  position: relative;
  display: flex;
  width: 100%;
  padding: 20px 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #edf3fe;
  box-shadow: 0px -5px 20px rgba(0, 0, 0, 0.3);
}
.call-action-wrap {
  width: 60%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.thank-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  padding: 12px;
}
.remote {
  position: relative;
  /* flex: 1; */
  justify-content: space-between;
}
.local {
  width: 120px;
  height: 190px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 100px;
  right: 10px;
  border-radius: 7px;
  z-index: 11;
  background-color: #fff;
  overflow: hidden;
}
.remote .call-img {
  justify-self: center;
}

.thank-detail {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.thank-detail img {
  /* height: 300px; */
  width: 90%;
}

.round-btn {
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.thank {
  padding: 0;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.small {
  padding: 0;
  text-align: center;
  margin: 0;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.small img {
  width: 100px !important;
  /* height: 20px !important; */
}

.small p {
  font-size: 15px !important;
}

.thank-bottom {
  display: flex;
  width: 100%;
  padding: 10px 10px;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.3);
}

.thank-content p:first-child {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 8px;
  text-align: center;
}
.thank-content p:nth-child(2) {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 60px;
  text-align: center;
}
.bottom-png {
  width: 122px;
  height: 42px;
  object-fit: cover;
  cursor: pointer;
}

.call-trans {
  position: absolute;
  background-color: #fff;
  top: 0;
  left: 0;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.declined-btn {
  /* width: 80%; */
  padding: 10px 50px;
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}

.speakerListContainer{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  padding: 22px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-Index: 3000;  
  width: 350px; 
  max-height: 250px;
  background: #fff;
  overflow-y: scroll; 
  scrollbar-color: #f0f0f0 #e0e0e0; /* Change colors as needed */
  scrollbar-width: thin; /* Adjust the width of the scrollbar */
  &::-webkit-scrollbar {
    width: 8px; /* Adjust the width of the scrollbar for WebKit browsers */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #555; /* Change the color of the scrollbar thumb */
    border-radius: 4px; /* Adjust the border radius */
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* Change the color of the scrollbar track */
    border-radius: 4px; /* Adjust the border radius */
  }
}